<template>
  <div class="users">
    <v-row>
      <v-col sm="12">
        <span class="headline">{{ pageName }}</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="3" class="ml-auto">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search this list..." single-line></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="allUsers"
          :loading="loading"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="onEditUser(item)">mdi-pencil</v-icon>
            <v-icon small @click="onDeleteUser(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-btn bottom color="amber" fab fixed right @click="onCreateUser">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <CreateUser :showDialog="dialog" :users="users" @dialog-state-change="onDialogStateChange" :key="createUserKey" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CreateUser from '@/components/CreateUser.vue';
export default {
  name: 'users',
  components: {
    CreateUser,
  },
  data() {
    return {
      pageName: 'Users',
      dialog: false,
      createUserKey: 0,
      search: null,
    };
  },
  computed: {
    ...mapGetters('users', { users: 'getUsers', loading: 'getLoading' }),
    headers() {
      return [
        {
          text: 'First Name',
          value: 'firstName',
        },
        {
          text: 'Last Name',
          value: 'lastName',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Region',
          value: 'region',
        },
        {
          text: 'Role',
          value: 'role',
        },
        // {
        //   text: 'THA 4.0',
        //   value: 'isTHA4',
        // },
        // {
        //   text: 'Actions',
        //   value: 'actions',
        //   sortable: false,
        // },
      ];
    },
    allUsers() {
      return this.users.filter((user) => user.userType == 'User');
    },
  },
  methods: {
    ...mapActions('users', { setUsers: 'setUsers', deleteUser: 'deleteUser' }),
    onDialogStateChange(state) {
      this.dialog = state;
    },
    onCreateUser() {
      this.dialog = true;
    },
    onEditUser() {
      this.dialog = true;
    },
    onDeleteUser(user) {
      this.deleteUser(user);
    },
  },
  created() {
    this.setUsers();
  },
  watch: {
    dialog: function(newValue) {
      if (!newValue) {
        console.log('Dialog force re-render: ', { newValue: newValue });
        this.createUserKey += 1;
      }
    },
  },
};
</script>
